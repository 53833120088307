import { Directus } from "@directus/sdk";

const directus = new Directus('https://api2.icautomatizados.com/');

export async function getClients() {
    const data = await directus.items("clients").readByQuery({
        fields: ["id","client_id", "client_name"],
        limit: -1
    });
    return data.data;
}

export async function getPlants() {
    const data = await directus.items("plants").readByQuery({
        fields: ["plant_name"],
        limit: -1
    });
    return data.data;
}
export async function getIndustry() {
    const data = await directus.items("industry").readByQuery({
        fields: ["industry_name"],
        limit: -1
    });
    return data.data;
}
export async function getPositions() {
    const data = await directus.items("positions").readByQuery({
        fields: ["position_name"],
        limit: -1
    });
    return data.data;
}
export async function getPersonalInteresets() {
    const data = await directus.items("personal_interests").readByQuery({
        fields: ["personal_interests_name"],
        limit: -1
    });
    return data.data;
}
export async function getInternalAreas() {
    const data = await directus.items("internal_areas").readByQuery({
        fields: ["internal_area_name"],
        limit: -1
    });
    return data.data;
}
export async function getIndustrialSubsegmentation() {
    const data = await directus.items("industrial_subsegmentation").readByQuery({
        fields: ["subsegmentation_name"],
        limit: -1
    });
    return data.data;
}

export async function CreateContact(request) {
    const data = await directus.items("contactos").createOne(request).then((res) => res.data);
    return data;
}
export async function UpdateContact(contact) {
    console.log(contact)
    const data = await directus.items("contactos").updateOne(contact.id, contact)
    return data;
}

export async function ReadAllContacts(contact) {
    const data = await directus.items("contactos").readByQuery({
        fields: ["id", "client_id", "client_name", "contact_name", "industry", "industrial_subsegmentation",  "types_of_plants",  "position",  "internal_area",  "phone",  "email",  "work_adress",  "personal_interests",  "decision_structure", "competitors",  "decision_making"],
        filter: {
            contact_name: { _eq: contact },
        },
    })
    .then((res) => res.data);
return data;
    
}

export async function ReadContacts() {

    const data = await directus.items("contactos").readByQuery({
        fields: ["id", "client_id", "client_name","contact_name", "industry", "industrial_subsegmentation",  "types_of_plants",  "position",  "internal_area",  "phone",  "email",  "work_adress",  "personal_interests",  "decision_structure", "competitors",  "decision_making"],
     
    })
    .then((res) => res.data);
return data;
}


export async function CreateClient(request) {
    const data = await directus.items("clients").createOne(request).then((res) => res.data);
    return data;
}
export async function CreatePlant(request) {
    const data = await directus.items("plants").createOne(request).then((res) => res.data);
    return data;
}

export async function CreateIndustry(request) {
    const data = await directus.items("industry").createOne(request).then((res) => res.data);
    return data;
}
export async function CreatePosition(request) {
    const data = await directus.items("positions").createOne(request).then((res) => res.data);
    return data;
}
export async function CreateInterest(request) {
    const data = await directus.items("personal_interests").createOne(request).then((res) => res.data);
    return data;
}
export async function CreateArea(request) {
    const data = await directus.items("internal_areas").createOne(request).then((res) => res.data);
    return data;
}
export async function CreateSubSegmentation(request) {
    const data = await directus.items("industrial_subsegmentation").createOne(request).then((res) => res.data);
    return data;
}

