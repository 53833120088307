import React, { useState, useEffect } from 'react';
import CreatableSelect from 'react-select/creatable';
import '../components/styles.css'
import { CreateContact, ReadAllContacts, ReadContacts, UpdateContact,
   getClients, getPlants, getIndustry, getPositions, getPersonalInteresets, getInternalAreas, getIndustrialSubsegmentation,
   CreateClient, CreatePlant, CreateIndustry, CreatePosition, CreateInterest, CreateArea, CreateSubSegmentation  } from '../store/directusSdk';
import correo from '../img/correo-electronico.png'
import Confetti from 'react-confetti'
import Popup from './Popup';
const FormularioCliente = () => {

  const [data, setData] = useState({ clients: [], plants: [], industry: [], positions: [], personalInterests: [], internalAreas: [], industrialSubsegmentation: []});

  const [isSend, setisSend] = useState(false)
  const initial_state = {
    client_id: '',
    client_name: '',
    industry: '',
    industrial_subsegmentation: '',
    types_of_plants: '',
    contact_name: '',
    internal_area: '',
    position: '',
    work_address: '',
    email: '',
    phone: '',
    personal_interests: [],
    decision_structure: '',
    competitors: '',
    decision_making: '',
  }
  const [formularioStateToUpdate, SetformularioStateToUpdate]= useState(false);
  const [formData, setFormData] = useState(initial_state);// formulario
  const [contact, setContact] = useState([]);
  const [selectedContact, setSelectedContact] = useState(null); // Nuevo estado para el contacto seleccionados
  const [isCleared, setIsCleared] = useState(false); // Nuevo estado para rastrear si se eliminó la selección
  const [popUp, setPopUp] = useState({
    isOpen: false,
    closePopup: () => { setPopUp(prev => ({ ...prev, isOpen: false })) }
  });
  const readName = async () => {
    const res = await ReadContacts(formData.contact_name).then(res => {
      setContact(res)
    })
    return res
  }
  useEffect(() => {
    getClients().then(data => {
      setData(prev => ({ ...prev, clients: data }))
    })
    getPlants().then(data => {
      setData(prev => ({ ...prev, plants: data }))
    })
    getIndustry().then(data => {
      setData(prev => ({ ...prev, industry: data }))
    })
    getPositions().then(data => {
      setData(prev => ({ ...prev, positions: data }))
    })
    getPersonalInteresets().then(data => {
      setData(prev => ({ ...prev, personalInterests: data }))
    })
    getInternalAreas().then(data => {
      setData(prev => ({ ...prev, internalAreas: data }))
    })
    getIndustrialSubsegmentation().then(data => {
      setData(prev => ({ ...prev, industrialSubsegmentation: data }))
    })
    
  }, [])

  useEffect(() => {
    readName()
    selectedContact && ReadAllContacts(formData.contact_name).then(res => {
      console.log('se ejecuto efecto')
      console.log(res.length)
      if(res.length>=1){
      setPopUp(prev => ({ ...prev, isOpen: true }))      
      }
    })


  }, [selectedContact])

  useEffect(() => {
    // Este efecto se ejecutará cada vez que formData o isCleared cambie
    if (isCleared) {
      SetformularioStateToUpdate(false)
      // Si isCleared es true (se eliminó la selección), restablece formData al estado inicial
      setFormData(initial_state);
      setSelectedContact(null); // Restablece selectedContact a null
      setIsCleared(false); // Restablece isCleared a false para evitar bucles infinitos
    }
  }, [formData, isCleared]);


  const getOptions = (data, value) => {
    const options = data.map(item => ({
      value: item[value],
      label: item[value]
    }));
    return options
  }

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    console.log(name, value)
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  
  const optionsClientes = data?.clients.map(item => ({
    id: item.cliente_id,
    value: item.client_name,
    label: item.client_name
  }))

  const optionsContacts = getOptions(contact, 'contact_name')
  const optionsTipoIndustria = getOptions(data.industry, 'industry_name')
  const optionsTipoPlanta = getOptions(data.plants, 'plant_name')
  const optionsSubSegmento = getOptions(data.industrialSubsegmentation, 'subsegmentation_name')
  const optionsIntereses = getOptions(data.personalInterests, 'personal_interests_name')
  const optionsposition = getOptions(data.positions, 'position_name')
  const optionsArea = getOptions(data.internalAreas, 'internal_area_name')

  const handleSubmit = (event) => {
    event.preventDefault();
    formularioStateToUpdate?
    UpdateContact(formData).then(() => {
      setisSend(prev => !prev)
      setFormData(initial_state)
      setSelectedContact(null)
    })
      .catch((error) => console.log(error))

    :
    CreateContact(formData).then(() => {
      setisSend(prev => !prev)
      setFormData(initial_state)
      setSelectedContact(null)
    })
      .catch((error) => console.log(error))

  };
  const handleClear = () => {
    // Esta función se llama cuando se elimina la selección en un CreatableSelect
    setIsCleared(true); // Establece isCleared en true para activar el efecto
  };
  const handleAccept = () => {
    console.log(typeof(selectedContact))
    contact.map(item => {
      item.contact_name === selectedContact &&
      setFormData(item);
    })
    console.log(selectedContact)
    setPopUp(prev => ({ ...prev, isOpen: false }))
    SetformularioStateToUpdate(true)
  }
  console.log(formData)
  return (
    <div>
      <Popup isOpen={popUp.isOpen} closePopup={popUp.closePopup} handleAccept={handleAccept} />
      {isSend === false ?
        <form onSubmit={handleSubmit}>
          <h2>Registro De Contactos</h2>
          <h4>Nombre del contacto:</h4>
          <CreatableSelect
            isClearable
            options={optionsContacts}
            onChange={(selectedOption) => {
              if (selectedOption) {
                // Si se selecciona una opción, actualiza formData
                setFormData((prevData) => ({ ...prevData, contact_name: selectedOption?.value }))
                setSelectedContact(selectedOption.value);
              } else {
                // Si se elimina la selección, llama a handleClear
                handleClear();
              }
            }}
            isSearchable={true}
            placeholder='Crea o Selecciona un nombre de contacto'
          />

          <h4>Nombre del cliente o empresa</h4>
          <CreatableSelect
            isClearable
            value={{ label: formData.client_name, value: formData.client_name } || ''}
            options={optionsClientes}
            onChange={(selectedOption, actionMeta) =>{
              if (actionMeta.action === "create-option") {
                console.log("se creo una nueva opcion")
                CreateClient({ client_name: selectedOption.value })
              }
              setFormData((prevData) => ({ ...prevData, client_id: selectedOption?.value, client_name: selectedOption?.label }))}
            } 
            isSearchable={true}
            placeholder='Seleccione un nombre de cliente'
            required
            tabSelectsValue={formData.client_name}
          />
          <h4>Tipo de Industria:</h4>
          <CreatableSelect
            isClearable
            value={{ label: formData.industry, value: formData.industry } || ''}
            options={optionsTipoIndustria}
            onChange={(selectedOption, actionMeta) => {
              if (actionMeta.action === "create-option") {
                console.log("se creo una nueva opcion")
                CreateIndustry({ industry_name: selectedOption.value })
              }
              setFormData((prevData) => ({ ...prevData, industry: selectedOption?.value }))}
            }
            isSearchable={true}
            required
            placeholder='Selecciona o crea un tipo de Industria'
          />
          <h4>Sub Segmentación industrial:</h4>

          <CreatableSelect
            isClearable
            value={{ label: formData.industrial_subsegmentation, value: formData.industrial_subsegmentation }|| ''}
            options={optionsSubSegmento}
            onChange={(selectedOption, actionMeta)=> {
              if (actionMeta.action === "create-option") {
                CreateSubSegmentation({ subsegmentation_name: selectedOption.value })
              }
              setFormData((prevData) => ({ ...prevData, industrial_subsegmentation: selectedOption?.value }))}
            }
            isSearchable={true}
            placeholder='Selecciona o crea una Sub Segmentación'

          />
          <h4>Tipos de Plantas:</h4>

          <CreatableSelect
            isClearable
            options={optionsTipoPlanta}
            value={{ label: formData.types_of_plants, value: formData.types_of_plants } || ''}
            onChange={(selectedOption, actionMeta) =>{
              if (actionMeta.action === "create-option") {
                CreatePlant({ plant_name: selectedOption.value })
              }
              setFormData((prevData) => ({ ...prevData, types_of_plants: selectedOption?.value }))}
            } 
            isSearchable={true}
            placeholder='Selecciona o crea un tipo de planta'
          />
          <h4>Posición:</h4>
          <CreatableSelect
            isClearable
            options={optionsposition}
            value={{ label: formData.position, value: formData.position } || ''}
            onChange={(selectedOption, actionMeta) =>{
              if (actionMeta.action === "create-option") {
                CreatePosition({ position_name: selectedOption.value })
              }
              setFormData((prevData) => ({ ...prevData, position: selectedOption?.value }))}
            } 
            placeholder='Selecciona o crea una posición'
          />
          <h4>Area Interna:</h4>
          <CreatableSelect
            options={optionsArea}
            value={{ label: formData.internal_area, value: formData.internal_area } || ''}
            onChange={(selectedOption, actionMeta) =>{
              if (actionMeta.action === "create-option") {
                CreateArea({ internal_area_name: selectedOption.value })
              }
              setFormData((prevData) => ({ ...prevData, internal_area: selectedOption?.value }))}
            } 
            placeholder='Selecciona o crea un area interna'
          />
          <h4>Teléfono:</h4>
          <input className='input-field'
            type="text"
            id="phone"
            name="phone"
            value={formData.phone}
            onChange={handleInputChange}
            placeholder='Escriba el teléfono o celular...'

          />
          <h4>email:</h4>
          <input className='input-field'
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleInputChange}
            placeholder='ejemplo@gmail.com'
          />
          <h4>Direccion de trabajo:</h4>
          <input className='input-field'
            type="text"
            id="work_address"
            name="work_address"
            value={formData.work_address}
            onChange={handleInputChange}
            placeholder='Escriba la dirección del trabajo ...'
          />
          <h4>Intereses Personales:</h4>
          <CreatableSelect
            options={optionsIntereses}
            closeMenuOnSelect={false}
            value={formData?.personal_interests?.map((interes, index) => ({
              label: interes,
              value: interes 
            }))|| ''}
            onChange={(selectedOptions, actionMeta) => {
              if (actionMeta.action === "create-option") {
                selectedOptions.map((selected) => {
                  if (selected.__isNew__ === true) {
                    CreateInterest({ personal_interests_name: selected.value })
                  }
                })
            
              }
              const selectedLabels = selectedOptions.map((option) => option?.label);
              setFormData((prevData) => ({
                ...prevData,
                personal_interests: selectedLabels,
              }));
            }}
            placeholder='Escriba los intereses personales...'
            isMulti
          />
          <h4>Estructura de desiciones:</h4>
          <textarea className='input-field'
            type="text"
            id="decision_structure"
            name="decision_structure"
            value={formData.decision_structure}
            onChange={handleInputChange}
            placeholder='Describa la estructura de desiciones'
          />

          <h4>Competidores:</h4>
          <input className='input-field'
            type="text"
            id="competitors"
            name="competitors"
            value={formData.competitors}
            onChange={handleInputChange}
            placeholder='Describa Con quienes competimos...'

          />
          <h4>Toma de desición</h4>
          <textarea className='input-field'
            type="text"
            id="decision_making"
            name="decision_making"
            value={formData.decision_making}
            onChange={handleInputChange}
            placeholder='Por bajo costo o mejor valor percibido. Describa...'
          />
          <button>{formularioStateToUpdate? 'Actualizar' : 'Enviar'}</button> :
        </form>
        :
        <div className='confirmacion_container'>
          <Confetti />
          <h1>¡Gracias!</h1>
          {formularioStateToUpdate?
          <h3 className='texto_confirmacion'>Contacto actualizado exitosamente.</h3>:
          <h3 className='texto_confirmacion'>Tu formulario se envió exitosamente.</h3>}
          <img src={correo} alt='animation' />
          <button onClick={() => setisSend(prev => !prev)}>Crear uno nuevo</button>
        </div>
      }
    </div>
  );

}
export default FormularioCliente
