import React, { useState } from 'react';

const Popup = ({isOpen, closePopup, handleAccept }) => {

  return (
    <div>
      {isOpen && (
        <div className="popup-overlay">
          <div className="popup">
            <h3>Este contacto ya existe</h3>
            <p>¿Estás seguro que deseas editarlo?</p>
            <button className="cancel-button" onClick={()=> closePopup()}>Cancelar</button>
            <button className="accept-button" onClick={()=> handleAccept()}>Aceptar</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Popup;
