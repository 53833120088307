import logo from './img/ica-logo.png';
import './App.css';
import FormularioCliente from './components/FormularioCliente';
function App() {
  return (
    <div className="App">
      <div className='formularioContainer'>
        <header className='formularioHeader'>
      <img className='logo' height={100} width={210} src={logo} alt='logo ICAUTOMATIZADOS' />
        </header>
      <FormularioCliente />
    </div>
      </div>
  );
}

export default App;
